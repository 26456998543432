import React from "react"
import "./Logo.scss"

function Logo({ data }) {

    let logo = process.env.PUBLIC_URL + "/assets/img/GallusmarktLogo.png"

    let { number, countdownDate, logoText } = data || {}

    return (
        <div className="Logo">
            <img src={logo} />
            <div className="content">
                <span className="first">{number}. Grünberger</span>
                <span className="big">Gallusmarkt</span>
                <span className="last">{logoText}</span>
            </div>
        </div>
    )
}
export default Logo