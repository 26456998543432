import React from 'react'
import { Helmet } from 'react-helmet'
import DefaultPage from '../components/pages/DefaultPage'
import { getDataFromSingleComponent, StrapiMain } from "../services/useApi"
import "./ProgrammPage.scss"

function ProgrammPage({ data }) {

    let year = new Date(data.countdownDate).getFullYear()
    return (
        <DefaultPage uri="programm" className='ProgrammPage'>
            <Helmet>
                <title>Programm – Gallusmarkt Grünberg</title>
                <meta name="description" content="Hier finden Sie das offizielle Programm zum Gallusmarkt 2022." />
            </Helmet>



            <div className="p-3 container">
                <h1>Programm {year}</h1>
                <p className='mb-5'>
                    <strong>{data.number}. Grünberger Gallusmarkt</strong>
                </p>

                {data.showProgramm ?
                    <Content data={data} />
                    :
                    <div className='mb-5 pb-5'>
                        <h2>Das Programm ist noch in Arbeit...</h2>
                        <p>Unsere Mitarbeiter:innen arbeiten noch an dem Programm für {year}. Bitte schauen Sie später nochmal vorbei!</p>
                    </div>
                }
            </div>
        </DefaultPage>
    )
}
export default ProgrammPage

function Content({ data }) {
    let programmPdf = getDataFromSingleComponent(data.programmPdf) || {}


    const downloads = [
        {
            title: programmPdf?.caption || "PDF-Programm",
            url: StrapiMain + programmPdf?.url,
            size: "In neuem Tab öffnen",
        },
    ]

    return (
        <div className="entry-content ">
            {/* Auflistung von den Tagen */}
            {data.days.map((i, k) =>
                <DayItem {...i} key={k} />
            )}
            <hr></hr>
            <h2>Programm für Unterwegs</h2>
            <div className="mt-5 row">
                {downloads.map((i, k) =>
                    <div className="col-12 col-lg-6 mb-3">
                        <a className="w-100" href={i.url} key={k} target="_blank">
                            <button className="w-100">
                                {i.title}
                                <br />
                                <small>{i.size}</small>
                            </button>
                        </a>
                    </div>
                )}
            </div>
        </div>

    )
}

const PDF_FOLDER = process.env.PUBLIC_URL + "/assets/pdf/"

function DayItem({ title, description }) {
    const strapiUrl = StrapiMain;

    const renderContent = (description) => {
        // Teile den Text auf, ohne die Bildmarkierungen zu entfernen
        const parts = description.split(/(!\[[^\]]*\]\(([^)]+)\))/g);

        return parts.map((part, index) => {
            const imageMatch = part.match(/!\[.*?\]\((.*?)\)/); // Finde die Bild-URI

            if (imageMatch) {
                const imageUrl = strapiUrl + imageMatch[1]; // Hängt StrapiMain an die URI
                return <img style={{ maxWidth: 800 }} className='w-100' key={index} src={imageUrl} alt="content-image" />;
            }

            // Entferne Teile, die mit '/uploads/' beginnen
            const cleanedPart = part.replace(/\/uploads\/[^\s)]+/g, '');

            // Rendere nur Text-Abschnitte, wenn kein Bild gefunden wird
            return cleanedPart.trim() ? <p key={index}>{cleanedPart.trim()}</p> : null;
        }).filter(part => part !== null); // Filtere null-Werte heraus
    };


    return (
        <div className="mb-5">
            <h2>{title}</h2>
            <div>
                {renderContent(description)}
            </div>
        </div>
    );
}
