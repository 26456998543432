import React from "react"


function GhostLoader_HeaderImage({ }) {
    return (
        <div className="ImageHeader loader">
            <div className="imgBox skeleton" />
        </div>
    )
}
export default GhostLoader_HeaderImage