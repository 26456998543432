import React from "react"
import Skyline from "../Skyline/Skyline"
import "./ImageHeader.scss"

function ImageHeader({ source, showSkyline = true, alternativeText, caption, postion = "center" }) {

    return (
        <div className="ImageHeader ">
            <div className='imgBox skeleton' alt={alternativeText} style={{ backgroundImage: `url(${source})`, backgroundPosition: `center ${postion}` }}>
            </div>
            {showSkyline && <Skyline />}

            {caption && <div className="container pt-1">
                <div className="d-flex flex-row-reverse">
                    <small>{caption}</small>
                </div>
            </div>}
        </div>
    )
}
export default ImageHeader