import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import './App.scss';
import Footer from './components/Footer';
import NavTop from './components/NavTop';
import AusstellerFormularPage from "./container/AusstellerFormularPage";
import AusstellerPage from './container/AusstellerPage';
import GeschichtePage from './container/GeschichtePage';
import HomePage from './container/HomePage';
import ImpressionenPage from './container/ImpressionenPage';
import LiedPage from './container/LiedPage';
import ProgrammPage from './container/ProgrammPage';
import ScrollToTop from './functions/ScrollToTop';
import { useApi } from "./services/useApi";

function App() {

  let { Data, Loading, Error } = useApi("/startseite?populate=*")
  if (Loading) return <p className="m-5 text-center">Die Website wird geladen...</p>
  if (Error) return <p>Wartungsarbeiten. Bitte versucehen Sie es später nochmal.</p>
  if (Data) {
    let year = new Date(Data.countdownDate).getFullYear() || "2023"
    Data.year = year
    return (
      <Router>
        <div className="App">
          <ScrollToTop />
          <NavTop data={Data} />
          <div className="pages">
            <Routes>
              <Route path="/" element={<HomePage data={Data} />} />
              <Route path="/aussteller" element={<AusstellerPage data={Data} />} />
              <Route path="/aussteller/beta-geheimer-link-vorschau" element={<AusstellerFormularPage data={Data} />} />
              <Route path="/programm" element={<ProgrammPage data={Data} />} />
              <Route path="/geschichte" element={<GeschichtePage data={Data} />} />
              <Route path="/impressionen" element={<ImpressionenPage data={Data} />} />
              <Route path="/lieder" element={<LiedPage data={Data} />} />
              <Route path="*" element={<p>Seite nicht gefunden.</p>} /> {/* Fallback Route */}
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    )
  }
}
export default App;