import React from 'react';
import 'react-awesome-slider/dist/styles.css';
import { Helmet } from 'react-helmet';
import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom';
import GallusmarktStoryImg from "../assets/images/story.jpg";
import Impressionen from '../components/Impressionen';
import MainCountdown from '../components/MainCountdown';
import DefaultPage from '../components/pages/DefaultPage';
import "./HomePage.scss";

function HomePage({ data }) {
    return (
        <DefaultPage uri="index" className="HomePage">
            <Helmet>
                <title>Gallusmarkt in Grünberg 🍻 </title>
                {/* <meta name="description" content="Nested component" /> */}
            </Helmet>
            <div className="box box-1">
                <div className="container p-0 p-lg-5">
                    <h1 className="text-center">Gallusmarkt in Grünberg</h1>
                    {/* <span className="subTitle"> <i className="fad fa-location"></i> Grünberg</span> */}
                    <MainCountdown date={data.countdownDate} />
                    {data.showProgramm &&
                        <div className="d-flex justify-content-center align-items-center">
                            <Link to="/programm">
                                <button>Weiter zum Programm</button>
                            </Link>
                        </div>
                    }
                </div>
            </div>
            <div className="box box-2 p-0">
                <div className="container p-0">
                    <h2 className="text-center mt-5 mb-5">{data.impressionTitle || "Impressionen"}</h2>
                    <Impressionen impressions={data.impressions?.data} limit={9} />
                    <br />
                    <Link to="/impressionen/">
                        <button className="mb-5">Alle anzeigen</button>
                    </Link>
                </div>
            </div>
            {/* <div className="box box-3">
                <div className="container">
                    <div className="news">
                        <a href="https://www.gruenberg.de/rathaus-buergerservice/aktuelles/aktuelle-mitteilungen/2021/gruenberger-gallusmarkt-wird-erneut-abegsagt/"
                            target="_blank">

                            <div className="newsItem col-12 col-md-4">
                                <img src="https://blog.dashdesign.eu/wp-content/uploads/2021/08/IMG_20191015_211752-1024x768.jpg" className="w-100" />
                                <div ><small>20. Juli 2021</small></div>
                                <h3>Gallusmarkt erneut abgesagt</h3>
                                <p>Gallusmarkt wird erneut abgesagt. Grünberger Schaufenster voraussichtlich erst wieder in 2023.</p>
                                <button>Mehr erfahren</button>
                            </div>
                        </a>
                    </div>
                </div>
            </div> */}
            <div className="box box-4">
                <Parallax
                    // style={{ minHeight: "40vh", }}
                    strength={100}
                    blur={{ min: -1, max: 3 }}
                    bgImage={GallusmarktStoryImg}
                >
                    <div className="innerBox p-5 mt-5 mb-5 m-2 ">
                        <h2>Die Geschichte des Gallusmarkts</h2><br />
                        <p>Der Gallusmarkt in Grünberg, das „Oberhessische Oktoberfest“, ist einer der ältesten Jahrmärkte in Hessen. Benannt ist er nach dem Heiligen Gallus, dessen Namenstag am 16. Oktober gefeiert wird.</p>
                        <br></br>
                        <Link to="/geschichte/">
                            <button>Mehr erfahren</button>
                        </Link>
                    </div>
                </Parallax>
            </div>
        </DefaultPage>
    )
}
export default HomePage

// countdownDate: '2023-10-13T22:00:00.000Z', number: 543, impressionTitle: 'Tetgrdgh', showProgramm: null, createdAt: '2023-02-26T23:41:12.929Z', …}
// countdownDate
// : 
// "2023-10-13T22:00:00.000Z"
// createdAt
// : 
// "2023-02-26T23:41:12.929Z"
// days
// : 
// []
// formulars
// : 
// {data: null}
// impressionTitle
// : 
// "Tetgrdgh"
// impressions
// : 
// {data: Array(20)}
// logo
// : 
// {data: {…}}
// number
// : 
// 543
// programmPdf
// : 
// {data: null}
// publishedAt
// : 
// "2023-02-26T23:51:54.967Z"
// showProgramm
// : 
// null
// updatedAt
// : 
// "2023-02-26T23:59:24.460Z"