import React from 'react';
import { Helmet } from 'react-helmet';
import DefaultPage from '../components/pages/DefaultPage';
import { StrapiMain } from '../services/useApi';
import AusstellerFormularPage from './AusstellerFormularPage';



function AusstellerPage({ data }) {

    //Ab dem 16.10.2024 wird die Bewerbung für den Gallusmarkt 2025 freigeschaltet
    const targetDate = new Date(2024, 9, 16, 8, 0, 0);
    const currentDate = new Date();

    if (targetDate < currentDate) { //Wenn das aktuelle Datum nach dem 16.10.2024 ist, wird das Bewerbungsformular angezeigt
        return <AusstellerFormularPage />
    }
    return (
        <DefaultPage uri="aussteller" >
            <Helmet>
                <title>Bewerbung | Gallusmarkt in Grünberg</title>
                <meta name="description" content="Der Gallusmarkt in Grünberg ist einer der ältesten Jahrmärkte in Hessen. Benannt ist er nach dem Heiligen Gallus, dessen Namenstag am 16. Oktober gefeiert wird." />
            </Helmet>


            <div className="p-3 container">
                <h1>Bewerbung</h1>
                <Content formulars={data?.formulars?.data} />
            </div>
        </DefaultPage>
    )
}
export default AusstellerPage

function Content({ formulars = [] }) {


    if (!formulars?.length) return <p>Es sind aktuell Bewerbungsformulare verfügbar.</p>


    return (
        <div className="entry-content mb-5">
            <p>Hier finden Sie diverse Bewerbungsformulare.</p>
            <div className="mt-5">
                {formulars?.map && formulars.map((i, k) =>
                    <Item {...i.attributes} key={k} />
                )}
            </div>
        </div>
    )
}

function Item({ name, url, ext, size }) {
    url = StrapiMain + url
    return (
        <div className="col-12 col-lg-6 p-2">
            <a className="w-100" href={url} target="_blank">
                <button className="w-100">
                    {name}
                    <br />
                    <small>{size} mb</small>
                </button>
            </a>
        </div>
    )
}