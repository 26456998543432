import React from "react";
import { getDataFromSingleComponent, getSrcFromSingleComponent } from "../services/useApi";

function StrapiSingleImage(props) {
    let { src, prefix } = props || {}
    let data = getDataFromSingleComponent(src)
    let imgSrc = getSrcFromSingleComponent(src, prefix)

    let { url, height, width, alternativeText, caption } = data || {}

    return (
        <img
            {...props}
            src={imgSrc}
            alt={alternativeText}
            caption={caption}
        />
    )
}
export default StrapiSingleImage