import React, { useEffect, useRef } from "react";
import "./IframePage.scss";

function IframePage({ url = "https://hessenapp.de/bewerbung-altstadtfest-fkeiuf7ehfz7g73z7z437zh74ufgzhfeklksh7h38k4ugf4hervjrhuff-rjfu4hrhf44-x8rj8ff-2025/" }) {
    const iframeRef = useRef();


    useEffect(() => {
        const iframe = iframeRef.current;
        const body = iframe.contentWindow.document.body;


        const header = iframe.contentDocument.querySelector("header.wp-block-template-part");
        // header.style.display = "none";

    }, [])


    return (
        <div className="IframePage">
            <iframe src={url} ref={iframeRef} />
        </div>
    )
}
export default IframePage