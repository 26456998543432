import axios from "axios"
import { useEffect, useState } from "react"

export const StrapiToken = "ba9a26723c134c9a0772080d8b6b1078101f9bcf63615ca93db5cb0c60791e1b5904e0ae6e4b4f47a372731123aef492e9106d74903c50e6740e6a07d63eeceb9cdcadb54acd1f8830d271931e973994392bf6484b7d61a2f9522a7ff8e31a6e0b4360c893f7630d84116e260fb6f856478ec2051eed77f1e97a3db32106bea8"
export const StrapiDefaultHeader = {
    Authorization: `Bearer ${StrapiToken}`
}

export const StrapiMain = "https://gallusmarkt-cms.mittelhessen.app"

export function useApi(endpoint) {
    const [Data, setData] = useState({})
    const [Error, setError] = useState(false)
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
        (
            async function () {
                setError(false)
                setLoading(true)

                let storageKey = `axiosCache_${endpoint}`;
                let cachedData = localStorage.getItem(storageKey);

                if (cachedData) {
                    const { expiry, data } = JSON.parse(cachedData);

                    // check if cache has expired
                    if (new Date().getTime() <= expiry) {
                        setData(data);
                        setLoading(false);
                        return;
                    }
                }

                let config = {
                    method: 'get',
                    url: StrapiMain + "/api" + endpoint,
                    headers: StrapiDefaultHeader,
                };

                // make call to api if no valid cache found
                axios(config)
                    .then(res => {
                        setData(res.data?.data?.attributes || res.data || {});
                        localStorage.setItem(storageKey, JSON.stringify({
                            expiry: new Date().getTime() + 60 * 1000,  // cache for 1 minute (60 seconds * 1000ms/sec)
                            data: res.data?.data?.attributes || res.data || {}
                        }));
                    })
                    .catch(() => setError(true))
                    .finally(() => setLoading(false))
            }
        )()
    }, [endpoint])

    return { Data, Loading, Error }
}


export function alenaxios(request) {
    // let cacheKey = request.url;
    // let cachedResponse = localStorage.getItem(cacheKey);

    // if (!request.method) request.method = "GET"

    // if (cachedResponse) {
    //     let cacheTimestamp = localStorage.getItem(cacheKey + ':t');
    //     let age = Date.now() / 1000 - cacheTimestamp;
    //     let cacheDurationInMilliseconds = 1000 * 10;

    //     if (age < cacheDurationInMilliseconds) {
    //         // console.log("Use local axios cache " + cacheKey)
    //         return Promise.resolve({ data: JSON.parse(cachedResponse) });
    //     } else {
    //         // console.log("Use axios  " + cacheKey)
    //         localStorage.removeItem(cacheKey);
    //         localStorage.removeItem(cacheKey + ':t');
    //     }
    // }

    // return axios(request)
    //     .then(response => {
    //         let responseToCache = JSON.stringify(response.data);
    //         localStorage.setItem(cacheKey, responseToCache);
    //         localStorage.setItem(cacheKey + ':t', Date.now() / 1000);
    //         return response;
    //     });
}




export function getSrcFromSingleComponent(singleImageObj, prefix = "") {
    if (!singleImageObj) return null
    let { url, alternativeText, caption, width, height } = singleImageObj?.data?.attributes || singleImageObj?.attributes || {}
    let http = StrapiMain

    url = url.replace("/uploads/", "/uploads/" + prefix)

    let imgUrl = http + url
    if (!imgUrl) return null
    return imgUrl
}

export function getDataFromSingleComponent(singleImageObj, prefix = "") {
    if (!singleImageObj) return null
    let result = singleImageObj?.data?.attributes || singleImageObj?.attributes || {}



    console.log(result)
    return { ...result, }
}