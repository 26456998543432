import React, { useState } from 'react';
import "./Impressionen.css";

import 'react-awesome-slider/dist/styles.css';
import { getDataFromSingleComponent, StrapiMain } from '../services/useApi';
import StrapiSingleImage from './StrapiSingleImage';


function Impressionen({ limit, impressions = [] }) {
    const [Open, setOpen] = useState(false);
    const [HandleLol, setHandleLol] = useState(0);
    const [Layer, setLayer] = useState(2);
    const impressionsShuffled = impressions.sort((a, b) => 0.5 - Math.random());
    function handlePlus() {
        if (Layer != 4) {
            setLayer(Layer + 1)
        } else {
            //easteregg:
            setHandleLol(HandleLol + 1)
            if (HandleLol == 42) {
                alert("Danke für die Antwort auf alle Fragen 😉 Lg Konrad")
            }
        }
    }
    function handleMinus() {
        if (Layer != -1) {
            setLayer(Layer - 1)
        }
    }
    return (
        <div className="images Impressionen">
            {limit != 9 &&
                <div className="d-none d-lg-flex mb-4">
                    <div className="col-6">
                        <button className="w-100" onClick={handlePlus}>
                            <i className="fad fa-layer-plus"></i>
                        </button>
                    </div>
                    <div className="col-6">
                        <button className="w-100" onClick={handleMinus}>
                            <i className="fad fa-layer-minus"></i>
                        </button>
                    </div>
                </div>
            }

            <div className="images">
                {impressionsShuffled
                    .slice(0, limit || impressions.length - 1)
                    .map((i, k) =>
                        <a
                            key={k}
                            className={"image col-12 mb-0 p-1 " + getGridSize(Layer)}
                            href={StrapiMain + getDataFromSingleComponent(i).url}
                            target="_blank"
                        >
                            <StrapiSingleImage className="w-100" src={i} prefix="large_" />
                        </a>
                    )}
            </div>
        </div>
    )
}
export default Impressionen

function getGridSize(Layer) {
    switch (Layer) {
        case -1: return "col-lg-1"
        case 0: return "col-lg-2"
        case 1: return "col-lg-3"
        case 2: return "col-lg-4"
        case 3: return "col-lg-6"
        case 4: return "col-lg-12"
    }
}