import React from 'react'
import { Helmet } from 'react-helmet'
import DefaultPage from '../components/pages/DefaultPage'
import { useApi } from '../services/useApi'

function GeschichtePage() {
    return (
        <DefaultPage uri="geschichte">
            <Helmet>
                <title>Die Geschichte des Gallusmarktes  📸 | Gallusmarkt in Grünberg</title>
                <meta name="description" content="Der Gallusmarkt in Grünberg ist einer der ältesten Jahrmärkte in Hessen. Benannt ist er nach dem Heiligen Gallus, dessen Namenstag am 16. Oktober gefeiert wird." />
            </Helmet>

            <div className="p-3 container">
                <h1>Geschichte</h1>
                <Content />
            </div>
        </DefaultPage>
    )
}
export default GeschichtePage

function Content() {

    let { Data, Loading, Error } = useApi("/maertfraans?sort=startYear:DESC")

    console.log(Data)

    return (
        <div className="entry-content">
            <p><span style={{ lineHeight: '1.5' }}>Der Gallusmarkt in Grünberg ist einer der ältesten Jahrmärkte in Hessen. Benannt ist er nach dem Heiligen Gallus, dessen Namenstag am 16. Oktober gefeiert wird.</span></p>
            <p>1480 wurde dem damaligen Kaiser Friedrich III. durch Landgraf Heinrich von Hessen der Vorschlag unterbreitet, der Stadt Grünberg das Recht zu erteilen, einen Markt am Gallustag abzuhalten. Der römisch-deutsche Kaiser Friedrich III. erteilte daraufhin im Jahr 1481 der Stadt das Recht, am jeweiligen Tag des heiligen Papstes Kallistus I. einen Markt abzuhalten. Die kaiserliche Urkunde befindet sich heute im Museum im Spital der Stadt Grünberg.</p>
            <p>Das Areal ist seit 1869 die Käswiese, wobei der Krämermarkt 1972 auf die Gießener Straße und die Theo-Koch-Straße verlegt wurde, da sich das zur Verfügung stehende Gelände – vor allem durch den Bau der Gallushalle und die Errichtung eines größeren Festzeltes – verkleinerte. Zuvor hat sich der Gallusmarkt wohl auf dem Marktplatz sowie später, als er an Bedeutung und Größe gewann, rechts und links der Londorfer Straße abgespielt. Dort trägt ein Flurstück die Bezeichnung Auf dem Gallusmarkt. Wegen schlechter Bodenverhältnisse wurde der Markt 1838 vorübergehend auf die befestigten Wege der Laimenkaute verlegt.</p>
            <p>Seit 1948 ist dem Gallusmarkt eine moderne Viehauktion angeschlossen. Bereits im Mittelalter wurde Vieh versteigert, im 19.<br />Jahrhundert wurden dann nur Maschinen und Gerätschaften für die Landwirtschaft gezeigt, heute hat sich der Gallusmarkt zu einer allgemeinen Gewerbeschau für Landmaschinen und Viehzucht entwickelt. 2010 wurde aufgrund Veränderungen in der Landwirtschaft der Rindviehmarkt aus dem Programm genommen. Stattdessen fand eine Tierpräsentation statt. Beide Veranstaltungen sollen zukünftig im zweijährigen Wechsel abgehalten werden.</p>
            <p>Alljährlicher Höhepunkt des Marktes ist die 1951 eingeführte Original Grünberger Wurzelbürger-Bürsterei während des traditionellen Frühschoppens. Hierbei werden Zugezogene, welche sich ehrenamtlich für ihre Wahlheimat verdient gemacht haben, nach Absolvieren einer Mundartprobe in den Kreis der Grünberger aufgenommen.</p>
            <p>Seit 1972 findet parallel zum Gallusmarkt die Oberhessische Kunstausstellung in den Räumen des historischen Barfüßerklosters statt.</p>
            <div style={{ height: '32px' }} aria-hidden="true" className="wp-block-spacer" />
            <h2>Gallusmarktkommission</h2>
            <p>Gemeinsam mit der Stadt Grünberg ist die 1926 gegründete Gallusmarktkommission für die Programmgestaltung und den Ablauf sowie für die Bestückung des Rummelplatzes und die Vergabe an einen Festwirt verantwortlich. Die Kommission gliedert sich in verschiedene Unterausschüsse: den Kunstausschuss, die Grimmicher Bobbekist (ehemals Kinderfestausschuss) und den Bürstausschuss.</p>
            <div style={{ height: '32px' }} aria-hidden="true" className="wp-block-spacer" />
            <h2>Grimmicher Märtfraa</h2>
            <p>1974 wurde die Grimmicher Märtfraa (Grünberger Marktfrau) als Symbol des Marktes kreiert. Die Wahl trifft ein Gremium, welches sich aus dem Frühschoppen-Ausschuss der Gallusmarktkommission bildet. Im Wechsel wird eine Geschäftsfrau oder eine Private zur Marktfrau berufen, die – neben dem Repräsentieren des Marktes – mit Sitz und Stimme in der Kommission mitarbeitet.</p>
            <div style={{ height: '32px' }} aria-hidden="true" className="wp-block-spacer" />


            <h3>Die Märtfraan:</h3>

            {Loading && <p>Liste wird geladen...</p>}
            {Error && <p>Bitte laden Sie die Website erneut.</p>}
            {Data && <List data={Data.data} />}

            <p />
        </div>

    )
}

function List({ data = [] }) {
    return (
        <div>
            {data.map((i, k) => <Item {...i.attributes} key={k} index={k} />)}
        </div>
    )
}

function Item({ fullName, startYear, endYear, index, image }) {

    let zeitspanne = startYear != endYear ? `${startYear} - ${endYear}` : startYear

    return (
        <div>
            <p>
                <b>{fullName}</b><br />
                {index == 0 ?
                    <small>({startYear} - Heute)</small>
                    :
                    <small>({zeitspanne})</small>
                }
            </p>
        </div >
    )
}