import React from 'react';
import Countdown from 'react-countdown';
import "./MainCountdown.css";


// Random component
const Completionist = () => <p>Der Gallusmarkt hat begonnen!</p>

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {

    // const completed = 

    if (false) {
        return <Completionist />;
    } else {
        // Render a countdown
        return <div className="Countdown">
            <div className="row w-100">
                <div className=" col-3 m-0 p-1">
                    <div className="valueBox">
                        <span className="value">{days}</span>
                        <span className="key">Tage</span>
                    </div>
                </div>

                <div className=" col-3 m-0 p-1">
                    <div className="valueBox">
                        <span className="value">{hours}</span>
                        <span className="key">Std.</span>
                    </div>
                </div>
                <div className=" col-3 m-0 p-1">
                    <div className="valueBox">
                        <span className="value">{minutes}</span>
                        <span className="key">Min.</span>
                    </div>
                </div>
                <div className=" col-3 m-0 p-1">
                    <div className="valueBox">
                        <span className="value">{seconds}</span>
                        <span className="key">Sek.</span>
                    </div>
                </div>
            </div>
        </div>
    }
};

function MainCountdown({ date }) {
    return (
        <div className="MainCountdown">
            <Countdown
                date={new Date(date)}
                renderer={renderer}
            />
        </div>
    )
}

export default MainCountdown
