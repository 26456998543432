import React from "react"
import { getDataFromSingleComponent, getSrcFromSingleComponent, useApi } from "../../services/useApi"
import GhostLoader_HeaderImage from "../ImageHeader/GhostLoader_HeaderImage"
import ImageHeader from "../ImageHeader/ImageHeader"
import "./DefaultPage.scss"

function DefaultPage({ uri, children, className = "" }) {

    let { Data, Loading, Error, } = useApi("/pages?filters[uri][$eq]=" + uri + "&populate=headerImage")

    if (Loading) return <div className={"DefaultPage " + className}>
        <GhostLoader_HeaderImage />
        {children}
    </div>
    if (Error) return <div className={"DefaultPage " + className}>
        {/* <h1></h1> */}
        <p>Hinweis: Aktuell wird diese Seite überarbeitet. Schauen Sie bitte gleich noch einmal vorbei!</p>
        {children}
    </div>

    let { title, description, content, headerImage, showSkyline, headerImagePosition } = Data?.data[0]?.attributes

    console.log(headerImage)

    let imgSrc = getSrcFromSingleComponent(headerImage)
    let imgProps = getDataFromSingleComponent(headerImage) || {}

    console.log(imgProps)

    return (
        <div className={"DefaultPage " + className}>
            <ImageHeader source={imgSrc} showSkyline={showSkyline} {...imgProps} postion={headerImagePosition} />
            <div className="pageContent">
                {children}
            </div>
        </div>
    )
}
export default DefaultPage