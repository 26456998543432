import React from 'react'
import { Helmet } from 'react-helmet'
import Impressionen from '../components/Impressionen'
import DefaultPage from '../components/pages/DefaultPage'

function ImpressionenPage({ data }) {
    return (
        <DefaultPage uri="impressionen">
            <Helmet>
                <title>Impressionen vom Gallusmarkt 2023  📸 | Gallusmarkt in Grünberg</title>
                <meta name="description" content="Ausgewählte Bilder vom Grünberger Gallusmarkt." />
            </Helmet>

            <div className="p-3 container">
                <h1> {data.impressionTitle}</h1><br />
                <Impressionen impressions={data?.impressions?.data} />
            </div>
        </DefaultPage>
    )
}

export default ImpressionenPage
