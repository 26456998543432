import React from 'react'
import { Helmet } from 'react-helmet'
import DefaultPage from '../components/pages/DefaultPage'

function LiedPage() {
    return (
        <DefaultPage uri="lieder">
            <Helmet >
                <title>Gallusmarktlieder 🎧 | Gallusmarkt in Grünberg</title>
                <meta name="description" content="Für viele 'Grimmicher' gehört das Gallusmarktlied zu jedem 'Gallmärt' mit dazu." />
            </Helmet>

            <div className="p-3 container">
                <article id="post-277" className="article-wrap post-277 page type-page status-publish hentry">
                    <header className="entry-header">
                        <h1>Gallusmarktlieder</h1><br />
                    </header>{/* .entry-header */}



                    <h2>Das Gallusmarktlied</h2><br />
                    <div className="entry-content">
                        <p className="has-text-align-left"><strong>Von Dr. Karl Stein<br /></strong>(Melodie: O alte Burschenherrlichkeit)</p>
                        <figure className="wp-block-audio">
                            <audio className="w-100" controls src="https://gallusmarkt-gruenberg.de/wp-content/uploads/2016/08/Gallusmarktlied.mp3" />
                        </figure>
                        <img className="wp-block-image rounded w-100" src="https://gallusmarkt-gruenberg.de/wp-content/uploads/2016/08/lied.jpg" />

                        <div style={{ height: '50px' }} aria-hidden="true" className="wp-block-spacer" />
                        <div style={{ height: '50px' }} aria-hidden="true" className="wp-block-spacer" />
                        <h2 className="text-center">Liedtext</h2>
                        <p className="text-center">Mein Grünberg, traute Heimatstadt,<br />im schönen lande Hessen,<br />ich trage in der Seele dich<br />von jeher unvergessen.<br />Du schaust hinaus ins weite Land<br />bis an den fernen Himmelsrand,<br />bist gar ein fröhlich Städtchen,<br />hast tausend schöne Mädchen.</p>
                        <p className="text-center">Am schönsten aber ist und bleibt<br />dein Gallmarkt hoch in Ehren,<br />wer ihn und seine Freuden liebt,<br />muss immer wiederkehren<br />zu dir, du liebes altes Nest,<br />wo wir oftmals froh gewest<br />beim hellen Gläserklingen,<br />beim Scherzen und beim Singen.</p>
                        <p className="text-center">Gar manchen schon verschlang das Grab,<br />mit dem wir einst getrunken,<br />gar manches Glas in Stücke sprang,<br />manch Scherzwort ist versunken.<br />Doch nie versank der Heimat Glut!<br />Kein Buttermilch ist hessisch Blut!<br />Lasst uns die Gläser heben:<br />der Gallmarkt, der soll leben!<br />Und mag der Menschen Dasein auch<br />gar schnell vorübergleiten,<br />der Gallmarkt, der wird fortbestehen<br />bis in die fernsten Zeiten.<br />Wir halten bis zum letzten Krug<br />und bis zum letzten Atemzug<br />der Heimatstadt die Treue!<br />Der Heimatstadt die Treue!</p>
                    </div>{/* .entry-content */}


                    <hr />


                    <h2>Das Frühschoppengedicht</h2><br />
                    <div className="entry-content">

                        <p>Die zwei Grünberger Künstler Klaus „Giro“ Chomann und Martin „Bembelator“ Philippi haben gemeinsam das Lied „Das Frühschoppengedicht“ für den diesjährigen Gallmärt geschrieben. Der eine Bild und Text-Künstler, der andere Tonkünstler. Die beiden kennen sich schon Jahrzehnte - unter anderem von gemeinsamen Auftritten bei der Grimmicher Fassenacht: Giro als Zeichner und Texter und Martin als Fassnachtsmusikant. Die Idee kam ihnen an einem sonnigen Vormittag auf Grünbergs guter Stube - dem Marktplatz. „Was mache mer denn für den ausgefallenenen Gallmärt?“, fragte Martin. „Ich hab da ein Gedicht!“, sagte Giro. „Dann schick mer’s un ich mach ne Melodie dazu.“, erwiderte Martin.</p>

                        <p className="has-text-align-left">
                            <strong>Text:</strong> Klaus „Giro“ Chomann,  <strong>Musik:</strong> Martin „Bembelator“ Philippi
                        </p>
                        <figure className="wp-block-audio">
                            <audio className="w-100" controls src="https://www.gruenberg.de/rathaus-buergerservice/aktuelles/aktuelle-mitteilungen/2021/das-fruehshoppengedicht/das-fruehschoppengedicht.mp3?cid=cr1" />
                        </figure>
                        {/* <img className="wp-block-image rounded w-100" src="https://gallusmarkt-gruenberg.de/wp-content/uploads/2016/08/lied.jpg" /> */}

                        <div style={{ height: '50px' }} aria-hidden="true" className="wp-block-spacer" />
                        <h2 className="text-center">Liedtext</h2>
                        <p className="text-center">
                            Der Gallmärt macht ganz Grimmich bunter, beim Frühschoppen, da is man munter!<br />
                            Das is schon lange Tradition, Geselligkeit sitzt auf dem Thron.<br />
                            Die wunderbare Festlichkeit     zieht Gäste an von nah und weit!<br />
                            Doch leider muss man mal pausieren   mit froher Runde und den Bieren.<br />
                            <br />
                            Doch wir alle werden seh’n: Gallmärt wird wieder aufersteh’n!<br />
                            <br />
                            Un jeder wirklich Freude hat, in Grimmich - trauter Heimatstadt!<br />
                            Das Karussell vor’m Zelt soll fahren, am besten noch in hundert Jahren.<br />
                            Denn Grimmich, das bleibt uns’re Liebe, sowie die Wurzelbürscht‘ – e Griewe!<br />
                            Der Gallmärt macht ganz Grimmich bunter, beim Frühschoppen, da is man munter.<br />
                            <br />
                            Doch wir alle werden seh’n: Gallmärt wird wieder aufersteh’n!<br />
                            Doch wir alle werden seh’n: Gallmärt wird wieder aufersteh’n!<br />

                        </p>
                    </div>{/* .entry-content */}


                </article>

            </div>
        </DefaultPage>
    )
}

export default LiedPage



