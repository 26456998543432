import React from 'react'
import "./Footer.css"

function Footer() {
    return (
        <div className="Footer">
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                            <h4>Rechtliches</h4>
                            <a target="_blank" href="https://www.gruenberg.de/impressum/">Impressum</a>
                            <a target="_blank" href="https://www.gruenberg.de/datenschutz/">Datenschutz</a>
                        </div>
                        <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                            <h4>Kontakt</h4>
                            <a target="_blank" href="https://gruenberg.de/">Stadt Grünberg</a>
                        </div>
                        <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                            <h4>Webentwicklung:</h4>
                            <a target="_blank" href="https://hessenapp.de">Hessen App GmbH</a>
                        </div>
                    </div>
                </div>


                <svg xmlns="http://www.w3.org/2000/svg" width="1920.145" viewBox="0 0 1920.145 392.661">
                    <g id="svg" transform="translate(0 -58.339)">
                        <rect id="ground" width="1920" height="23" transform="translate(0 428)" />
                        <path id="dieb" d="M0,0H15.558L51.806-112.9,87.109,0h15.9V157.346H0Z" transform="translate(311.166 292)" />
                        <path id="k" d="M1.187,62.8l10.082-4.447,29.9-174.795L64.48,58.353,74.826,62.8V272.355H1.187Z" transform="translate(1582.166 174.781)" />
                        <path id="k2" d="M0,0,116.724-2.23l72,53.723h15.359l1.836,40.491h79.562v16.159H0Z" transform="translate(1634.667 342.565)" />
                        <path id="r" d="M31.528,54.7V23.738L43.809,7.122l12.63,16.616v20.8H87.445l51.569,62.087V131.19H0V82.61Z" transform="translate(1046 317)" />
                        <path id="h1" d="M23.075,1.257,99.063-.43l24.52,33.282V56.985H.066V35.619Z" transform="translate(1185 388)" />
                        <path id="h2" d="M29.4-12.324,80.489,67.3H.066V35.619Z" transform="translate(1308 378)" />
                        <path id="h3" d="M94.643,36.893l40.938-26.439L144.254,91H3.314L46.652,34.282Z" transform="translate(1454 353)" />
                        <path id="h5" d="M248.466-3.573,271.6,28.483H37.7L79.57-3.573h168.9Z" transform="translate(-17 421)" />
                        <path id="h4" d="M120.765,42.491V88H28.4V52.679L79.57-3.573Z" transform="translate(879 362)" />
                        <path id="h6" d="M99.874,34.446h15.868L162.107,1.641h96.178l70.429,60.628V91L23.926,96.656l4.47-55.9H41L72.675,68.832Z" transform="translate(386 351)" />
                        <path id="h4-2" data-name="h4" d="M144.121,83.525,120.765,91H28.4V52.948h21.81V40.474H67.771V51.5Z" transform="translate(686 360)" />
                    </g>
                </svg>

                <div className="copyright">
                    © {new Date().getFullYear() || "2023"} Magistrat der Stadt Grünberg - Alle Rechte vorbehalten.
                </div>



            </footer>
        </div>
    )
}

export default Footer
