import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import "./NavTop.scss";

function NavTop({ data }) {

    const [Menu, setMenu] = useState(false);


    return (
        <div>

            <div className="NavTop">
                <div className="container p-0 py-4 d-flex flex-column flex-lg-row align-items-center align-items-lg-center">
                    <Link to="/" className="col-12 col-lg">
                        <div className="d-flex flex-row align-items-cetner justify-content-between">

                            <Logo data={data} />

                            <div className="d-flex flex-row align-items-center  d-lg-none" onClick={() => setMenu(!Menu)}>
                                {Menu ?
                                    <i className="fas fa-times"></i>
                                    :
                                    <i className="fas fa-bars"></i>
                                }
                            </div>
                        </div>
                    </Link>



                    <div className={"menu pt-lg-0  flex-column flex-lg-row " + (Menu ? "isOpen" : null)}>

                        <div className="inner" onClick={() => setMenu(false)}>
                            <div className='container'>
                                <div className='d-flex flex-column flex-lg-row'>
                                    <Link to="/programm/">Programm</Link>
                                    <Link to="/impressionen/">Impressionen</Link>
                                    <Link to="/geschichte/">Geschichte</Link>
                                    <Link to="/lieder">Lieder</Link>
                                    <Link to="/aussteller/">Für Aussteller</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="NavTopPush" />
        </div>
    )
}

export default NavTop
